<template>
    <sidebar-general
        ref="sidebarCrearAsistencia"
        title="Crear asistencia"
        header-class="primary"
        aceptar="Enviar"
    >
        <div class="px-2 my-1">
            <div class="my-1">
                <label for="fecha">Fecha</label>
                <b-form-datepicker id="fecha"></b-form-datepicker>
            </div>
            <div class="my-1">
                <label for="nombre_asistido">Paciente</label>
                <v-select
                    id="nombre_asistido"
                    :options="['Gian', 'Oscar', 'Alejandra']"
                >
                </v-select>
            </div>
            <div class="my-1">
                <label for="caso">Caso presentado</label>
                <b-form-textarea></b-form-textarea>
            </div>
            <div class="my-1 d-inline-flex">
                <span class="mr-1">¿Se hará uso de algún dispositivo? </span>
                <b-form-checkbox v-model="deviceUsed" switch></b-form-checkbox>
            </div>
            <div v-show="deviceUsed">
                <label for="dispositivo"></label>
                <v-select
                    :options="[
                        'Blood pressure',
                        'Body composition',
                        'Weight scale   ',
                    ]"
                ></v-select>
            </div>
        </div>
    </sidebar-general>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
    setup(props, context) {
        const deviceUsed = ref(false);

        function toggle() {
            context.refs.sidebarCrearAsistencia.toggle();
        }

        return { deviceUsed, toggle };
    },
};
</script>
